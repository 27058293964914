.scrollable {
  overflow: auto;
}
.scrollable-y {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.width-auto {
  width: auto;
}
.width-max {
  width: max-content;
}
.width-min {
  width: min-content;
}
.width-100 {
  width: 100%;
}
.width-100-vh {
  width: 100vh;
}
.width-75 {
  width: 75%;
}
.width-75-vh {
  width: 75vh;
}
.width-60 {
  width: 60%;
}
.width-60-vh {
  width: 60vh;
}
.width-50 {
  width: 50%;
}
.width-50-vh {
  width: 50vh;
}

.height-auto {
  height: auto;
}
.height-100 {
  height: 100%;
}
.height-100-vh {
  height: 100vh;
}
.height-75 {
  height: 75%;
}
.height-75-vh {
  height: 75vh;
}
.height-60 {
  height: 60%;
}
.height-60-vh {
  height: 60vh;
}
.height-50 {
  height: 50%;
}
.height-50-vh {
  height: 50vh;
}

.flex-col-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flex-col-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex-col-evenly {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.flex-col-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.flex-row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flex-row-evenly {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.flex-row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
