/*
Datatable
*/

// datatable

div.dataTables_wrapper {
  div.dataTables_filter {
    text-align: right;

    @media (max-width: 767px) {
      text-align: center;
    }


    input {
      margin-left: 0.5em;
      margin-right: 0;
    }
  }
}

.table,
table {
  &.dataTable {
    border-collapse: collapse !important;
    &.dtr-inline.collapsed>tbody>tr>td {
      position: relative;

      &.dtr-control {
        padding-left: 30px;

        &:before {
          top: 50%;
          left: 5px;
          height: 14px;
          width: 14px;
          margin-top: -9px;
          display: block;
          position: absolute;
          color: $white;
          border: 2px solid $white;
          border-radius: 14px;
          box-sizing: content-box;
          text-align: center;
          text-indent: 0 !important;
          line-height: 14px;
          content: '+';
          background-color: $primary;
        }
      }
    }
  }
}
