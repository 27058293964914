@import "../variables";

.hover {
  cursor: pointer;
}

.sticky-box {
  position: sticky;
  top: 50px;
  z-index: 100;
}
