@import "../variables";

/* Font size */
.fs-5-5 {
  font-size: 1.1rem !important;
}
.fs-6-5 {
  font-size: 0.9rem !important;
}
.fs-7 {
  font-size: 0.8rem !important;
}
.fw-500 {
  font-weight: 500;
}
.fw-300 {
  font-weight: 300;
}
.fw-100 {
  font-weight: 100;
}
.font-suit {
  font-family: "SUIT", "Noto Sans KR", sans-serif;
}

/* Color */
.text-red {
  color: red;
}
.text-green {
  color: green;
}
.text-blue {
  color: blue;
}
.text-orange {
  color: $orange;
}
.text-yellow {
  color: $yellow;
}
.text-pink {
  color: $pink;
}
.text-indigo {
  color: $indigo;
}
.text-purple {
  color: $purple;
}
.text-teal {
  color: $teal;
}
.text-cyan {
  color: $cyan;
}
.text-gray {
  color: $gray-700;
}
/* Context color */
.text-primary {
  color: $primary;
}
.text-white {
  color: white !important;
}

/* Etc */
.text-pre-line {
  white-space: pre-line;
}
.text-underline {
  text-decoration: underline;
}

.text-overflow-ellipsis {
  overflow: hidden; // 박스에서 넘쳐난 텍스트 숨기기
  text-overflow: ellipsis; // ... 말줄임 효과
  white-space: nowrap; // 줄바꿈이 없어짐
  word-break: break-all; // 어절이 유지되지 않고 끊어져서 줄바꿈 됨
}
.text-overflow-keep {
  overflow: visible; // 박스에서 넘쳐난 텍스트 숨기기
  white-space: nowrap; // 줄바꿈이 없어짐
  word-break: keep-all; // 어절이 유지되지 않고 끊어져서 줄바꿈 됨
}

.line-height-100 {
  line-height: 100%;
}
.line-height-120 {
  line-height: 120%;
}
.line-height-140 {
  line-height: 140%;
}
.line-height-160 {
  line-height: 160%;
}
.line-height-180 {
  line-height: 180%;
}
.line-height-200 {
  line-height: 200%;
}

.text-align-left {
  text-align: left;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}