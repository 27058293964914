@import "assets/scss/app.scss";

.app-modal {
    .modal-content {
        border-radius: 6px;
        max-width: 320px;
        margin: auto;
        border: none;
        overflow: hidden;
        .modal-header {
            border: none;
            padding: 20px;
            .modal-title {
                font-weight: bold !important;
                font-size: calc(1.2rem + 0.3vw);
            }
        }
        .modal-body {
            padding: 20px;
        }
        .modal-footer {
            border: none;
            background-color: $primary;
            height: 45px;
            text-align: center;
            justify-content: center;
            color: white;
            font-weight: 100;
        }
    }
}
